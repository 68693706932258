<template>
   <div class="background-box">
      <div class="content">
         <slot>
            No content has been assigned to this box!
         </slot>
      </div>
   </div>
</template>

<script setup>
</script>

<style scoped>
@media (max-width: 750px) {
   .background-box {
      margin: 18px 0 0 0 !important;
   }

   .background-box .content {
      /* % prefered here. scales down better to mobile */
      padding: 0 5% 0 5% !important;
   }
}

.background-box {
   margin: 0 2.5% 18px 2.5%;

   background-color: var(--color-background);
   border-radius: 32px;
}

.background-box .content {
   padding: 0 7.5% 0 7.5%;
}

/* optional class specified when creating component. use thsi to have image/header inside etc */
.image-box {
   background-color: var(--color-image-overlay);
   position: relative;
   overflow: hidden;
}

.image-box::before {
   content: "";

   position: absolute;
   z-index: -1;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);

   display: block;
   width: 100%;
   height: 100%;

   background-size: cover;
   filter: blur(8px);
   -webkit-filter: blur(8px);
}

.image-box p {
   color: var(--color-white);
}

/* optional class, use when you want the darker version */
.dark {
   background-color: var(--color-shaded);
}
</style>

<style>
/* a ContentBox used as an image-box will 99% of the time have
 * a Header component at the top as well. we don't want to add
 * padding above it! so we apply to the 2nd child */
.image-box .content > *:nth-child(2) {
   padding-top: 200px;
}
.image-box .content > *:last-child {
   padding-bottom: 65px;
}
.image-box .content > p {
   font-size: 20px;
}
.dark .content p {
   color: var(--color-primary);
}
</style>
