<template>
   <div :class="darkmode ? 'app darkmode' : 'app'">
      <router-view />
      <Footer v-if="$route.name !== '404'" :darkmode_enabled="darkmode" @darkmode_toggled="() => toggleDarkmode()"/>
   </div>
</template>

<script setup>
import Footer from "./components/Footer.vue"
import { ref, onMounted } from "vue"
import { refresh } from "./data/data.js"

const darkmode = ref(true);

darkmode.value = localStorage.getItem("darkmode") != "false";

const toggleDarkmode = () => {
   darkmode.value = !darkmode.value;
   localStorage.setItem("darkmode", darkmode.value.toString());
}

onMounted(async () => {
   refresh()
})

</script>

<style>

@media (max-width: 750px)
{
   h1 {
      font-size: var(--font-header-mobile) !important;
      font-weight: 500;
   }

   p:not(.profile) {
      font-size: 18px !important;
   }
}

@font-face {
   font-family: "Inter", sans-serif;
   src: url("./assets/fonts/inter.ttf");
}

@font-face {
   font-family: "Franklin Gothic Medium", sans-serif;
   src: url("./assets/fonts/franklin_gothic.ttf");
}

.app {
   --page-width: 1440px;

   --color-primary: #000000;
   --color-secondary: #797979;
   --color-accent: #2D96D4;
   --color-accent2: #F5A4A4;
   --color-background: #EEEEEE;
   --color-body: #ffffff;
   --color-image-overlay: #1C1E1FCC;
   --color-shaded: #1C1E1F;
   --color-white: #f1f1f1;

   --invert: 0;

   --font-header: 64px;
   --font-header-mobile: 40px;
}

.app.darkmode {
   --page-width: 1440px;

   --color-primary: #e1e1e1;
   --color-secondary: #a0a0a0;
   --color-accent: #5a8196;
   --color-accent2: #e68787;
   --color-background: #202020;
   --color-body: #121212;
   --color-image-overlay: #1C1E1FCC;
   --color-shaded: #1C1E1F;
   --color-white: #f1f1f1;

   --invert: 1;

   --font-header: 64px;
   --font-header-mobile: 40px;
}

body {
   margin: 0;
}

.app {
   padding-top: 18px;
}

.app::before {
   background-color: var(--color-body);
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   content: "";
   z-index: -1;
}

p, a, h2, li {
   font-family: 'Inter', sans-serif;
   margin: 0;
}

a {
   color: var(--color-accent);
}

a:hover {
   color: var(--color-accent2);
}

h1, h3 {
   font-family: 'Franklin Gothic Medium', sans-serif;
   margin: 0;
}

/* main big blue headers */
h1 {
   font-size: var(--font-header);
   color: var(--color-accent);
   font-weight: 500;
   margin: 0 0 10px 0;
}

/* small all caps secondary headers */
h2 {
   font-size: 24px;
   font-weight: 500;
   text-transform: uppercase;
}

/* alternative main header, like h1 but all caps & colored black */
h3 {
   font-size: 64px;
   font-weight: 500;
   text-transform: uppercase;
}

img {
   border-radius: 32px;
}

.page {
   margin: auto;
   padding: 0 2% 0 2%;
   max-width: var(--page-width);
}
</style>
