<template>
   <div class="box">
      <div class="background" @click="quit()"></div>
      <div class="blurb">
         <img alt="quit" @click="quit()" class="quit" src="../../assets/icons/quit.webp" />
         <h2>{{ member.name }}</h2>
         <p class="tagline"><strong>{{ member.title }}</strong>, Class of {{ member.class }}</p>
         <p class="description">{{ member.description }}</p>
         <div class="links">
            <a 
               v-for="link in member.links"
               :key="link.link"
               target="__blank" 
               :href="link.link">
               <img
                  class="hover"
                  :src="get_link_icon(link.icon + '_hover')"
               />
               <img
                  class="link-icon"
                  :src="get_link_icon(link.icon)"
               />
            </a>
         </div>
      </div>
   </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"

const get_link_icon = (link) => {
   let images = require.context("../../assets/icons/", false, /\.webp$/)
   return images("./" + link + ".webp")
}

defineProps([ "member" ])
const emit = defineEmits([
   "quit"
])

const quit = () => {
   emit("quit")
}
</script>

<style scoped>
@media (max-width: 750px) {
   .blurb {
      min-width: 250px !important;
      width: 75%;
      max-width: 550px !important;

      padding: 36px !important;
   }
}

h2 {
   text-transform: none;
   font-size: 24px;
   margin: 0;
   font-weight: 600px;
   color: var(--color-primary);
}

p {
   margin-top: 2px;
}

.tagline {
   color: var(--color-secondary);
   font-size: 16px !important;
}

.description {
   font-size: 16px !important;
   margin-top: 12px;
   color: var(--color-primary);
}

img {
   width: 26px;
   border-radius: 0;

   cursor: pointer;

   opacity: 1;

   transition: 100ms opacity;
}

img.hover {
   position: absolute;
   opacity: 0;
}

a:hover .hover {
   opacity: 1;
}

a:hover .link-icon {
   opacity: 0;
}

.links {
   display: flex;
   flex-direction: row;
   gap: 10px;
   flex-wrap: wrap;

   padding-top: 30px;
}

.links a {
   filter: invert(var(--invert));
}

.box {
   z-index: 2;
   position: fixed;
   left: 0;
   top: 0;
}

.background {
   background-color: var(--color-image-overlay);
   width: 100vw;
   height: 100vh;
   max-width: 100%;
   max-height: 100%;
   margin: 0;
   
}

.blurb {
   z-index: 2;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);

   background-color: var(--color-background);
   border-radius: 32px;
   
   min-width: 450px;
   max-width: 500px;
   padding: 45px;
}

.quit {
   position: absolute;
   right: 24px;
   top: 24px;
   width: 16px;
   cursor: pointer;

   transition: 100ms opacity;

   filter: invert(var(--invert));
}

.quit:hover {
   opacity: 0.5;
}

</style>
