<template>
   <div class="reason-box">
      <img :src="image" />
      <div class="reason">
         <h2>{{ title }}</h2>
         <p>{{ content }}</p>
         <CustomButton :content="button_text" :url="button_url" />
      </div>
   </div>
</template>

<script>
import CustomButton from "../../components/CustomButton.vue";

export default {
   name: "JoinReason",
   components: {
      CustomButton
   },
   props: {
      title: String,
      content: String,
      button_text: String,
      button_url: String,
      image: String
   }
}

</script>

<style scoped>

@media (max-width: 750px) {
   /* mobile */
   .reason-box {
      height: auto !important;
   }
   img {
      position: static !important;
      width: 100% !important;
      min-height: 250px !important;
      max-height: 410px !important;
      height: 50vw !important;
      margin-top: 0 !important;
   }
   .reason {
      margin: 0 !important;
      width: 100% !important;
   }

   .reason .button {
      margin-bottom: 0 !important;
   }

   .reason h2 {
      padding-top: 16px;
      margin-bottom: 8px;
   }
}

.reason-box {
   position: relative;
   height: 410px;
}

.reason {
   width: 37.5%;
   margin-left: 55%;
}

.reason * {
   margin-bottom: 20px;
}

.reason-box.left .reason {
   margin-left: 5%;
}

h2, p {
   color: var(--color-primary);
}

h2 {
   text-transform: none;
   font-size: 36px;
   font-weight: bold;

   padding-top: 36px;
}

p {
   font-size: 20px;
}

img {
   width: 750px;
   height: 410px;
   object-fit: cover;

   position: absolute;
   top: 0%;
   right: 50%;
}

.reason-box.left img {
   left: 50%;
}

</style>
