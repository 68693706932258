<template>
   <div class="page">
      <ContentBox>
         <Header />
         <h1>Join Our Club</h1>
         <p>From hard-core enthusiasts to the occasional builder, we welcome all keyboard lovers!</p>
         <br />
         <p>You can find us on Discord, where we’re most active, and follow our social media!</p>
         <div class="links">
            <Link
               v-for="link in links"
               :key="link.title"
               :link="link"
            />
         </div>
         <p class="message">We're excited to meet you!</p>
      </ContentBox>
   </div>
</template>

<script setup>
import Header from "../../components/Header.vue"
import ContentBox from "../../components/ContentBox.vue"
import Link from "./Link.vue"
import { ref } from "vue"

const NewLink = (title, image, link) => {
   return {
      title: title,
      icon: image,
      url: link
   }
}

const links = ref([
   NewLink("Discord", require("../../assets/links-logos/discord.webp"), "https://discord.gg/xR5T7fyXTv"),
   NewLink("Events", require("../../assets/links-logos/calendar.webp"), "/events"),
   NewLink("Instagram", require("../../assets/links-logos/instagram.webp"), "https://www.instagram.com/keebsclubuci/"),
   //NewLink("Reddit", require("../../assets/links-logos/reddit.webp"), "404")
])
</script>

<style scoped>

@media (max-width: 750px) {
   .links {
      width: fit-content;
      display: grid !important;
      grid-template-columns: 1fr 1fr;
   }

   h1 {
      margin-top: 0 !important;
   }

   .message {
      padding: 48px 0 48px 0 !important;
   }
}

p {
   color: var(--color-primary);
}

h1 {
   margin-bottom: 18px;
   margin-top: 180px;
}
p:not(.message) {
   max-width: 450px;
   font-size: 20px;
}

.links {
   margin-top: 48px;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   gap: 18px;
}

.message {
   padding: 81px 0 81px 0;
   text-align: center;
   font-style: italic;
   font-size: 20px;
}
</style>
