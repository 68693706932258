<template>
   <div class="page">
      <ContentBox class="image-box">
         <Header />
         <h1>Spring Fling RSVP</h1>
         <p>An RSVP is required to attend!</p>
      </ContentBox>
      <ContentBox>
         <form ref="form" v-on:submit.stop.prevent="check_form" target="__blank" action="https://docs.google.com/forms/u/5/d/e/1FAIpQLScPOwrE8_oi-fglrnDKy4DGH_sY1kjgBPTgKkkDZ6ONFzTHaQ/formResponse">
            <label for="name">Name<span>*</span></label>
            <input placeholder="Santa Clack" ref="name_input" id="name" name="entry.841063112" type="text" />

            <label for="email">Email<span>*</span></label>
            <input placeholder="santaclack@northpole.com" ref="email_input" id="email" name="entry.95693100" type="text" />

            <label for="discord">Contact Information<span>*</span></label>
            <input placeholder="Discord, Phone number..." ref="contact_input" id="discord" name="entry.6006861" type="text" />

            <label for="items">Items<span>*</span></label>
            <p class="disclaimer">Fill this section out if you plan to bring keyboard items! If none, put N/A. <br /></p>
            <input placeholder="N/A" id="items" ref="items_input" name="entry.1615203299" type="text"/>


            <label for="signature">Signature<span>*</span></label>
            <p class="disclaimer">By initialing below, you are giving Keebs Club @ UCI permission to take and use your photos for future club promotions and content. You are also recognizing that Keebs Club @ UCI is not responsible for any lost or damaged goods.</p>
            <input placeholder="S.C." id="signature" ref="signature_input" type="text" name="entry.151541145" />

            <button><CustomButton content="Submit" /></button>

            <p ref="message_ref" class="message">{{ message }}</p>
         </form>

      </ContentBox>

   </div>
</template>

<script setup>

import ContentBox from "../../components/ContentBox.vue"
import CustomButton from "../../components/CustomButton.vue"
import Header from "../../components/Header.vue"

import { ref, onMounted } from "vue"

const name_input = ref();
const email_input = ref();
const contact_input = ref();
const items_input = ref();
const signature_input = ref();
const form = ref()
const message_ref = ref()

const message = ref(" ");

const form_inputs = [
   name_input,
   email_input,
   contact_input,
   items_input,
   signature_input
]

const check_form = () => {
   let num_filled_fields = 0
   form_inputs.forEach(input => {
      if (input.value.value == "") {
         input.value.classList.add("error");
         message.value = "Please fill all the fields!"
      } else {
         num_filled_fields++;
      }
   })

   if (num_filled_fields == form_inputs.length) {
      // we've filled out all the fields
      form.value.submit();
      message_ref.value.classList.add("success")
      message.value = "Successfully submitted!"
      // form_success.value = true;
   }
}

onMounted(() => {
   // set up auto remove error when typing
   form_inputs.forEach(input => {
      input.value.onkeypress = () => {
         input.value.classList.remove("error")
      }
   })
})

// import { onMounted } from "vue";

// const form_url = "https://docs.google.com/forms/d/e/1FAIpQLSdPxA2EFZs_EhuBn_TJXy8Y-Llrx2WqkqrGzoJizi5otiyxPg/formResponse?"


// const submit_form = () => {
//    fetch(form_url + new URLSearchParams({
//       "entry.319087347": "test initial",
//       "entry.1003261123": "full name test",
//       "entry.454156812": "student id test",
//       "entry.1090149863": "discord test",
//    }), {
//       method: "POST"
//    }).then(res => {
//       console.log("response: ")
//       console.log(res)
//    })
// }

// onMounted(() => {
//    let inputs = document.querySelectorAll("input[type='text']")
//    console.log(inputs)
//    submit_form()
// })

</script>

<style scoped>
@media(max-width: 750px) {
   .image-box h1 {
      padding-top: 0 !important;
   }

   iframe {
      padding-top: 5vw !important;
      padding-bottom: 5vw !important;
   }
}

.image-box::before {
   background-image: url("../../assets/events/22_winter_meet/1.webp");
}

iframe {
   padding-top: 64px;
   padding-bottom: 64px;
}

form {
   display: flex;
   flex-direction: column;

   padding-top: 64px;
   padding-bottom: 64px;
}

label {
   font-family: "Inter", sans-serif;
   font-weight: 600;
   font-size: 18px;
   color: var(--color-primary);
}
p {
   color: var(--color-secondary);
}

label span {
   color: red;
}

p {
   font-size: 16px;
   font-style: italic;
}

p.hint {
   font-size: 12px;
}

input[type="text"] {
   border: none;
   padding: 8px;
   margin-top: 10px;
   margin-bottom: 30px;
   max-width: 250px;

   font-family: "Inter", sans-serif;
   font-size: 16px;

   background-color: var(--color-body);
   color: var(--color-primary);
}

input[type="text"].error {
   outline: 1px solid red;
}

input.error {
   outline: 1px solid red;
}

.disclaimer {
   margin-bottom: 10px;
   margin-top: 10px;
   max-width: 600px;
}

button {
   border: none;
   background: none;
   width: fit-content;
}

.message {
   margin-top: 15px;
   font-style: normal;
   font-weight: bold;
   color: rgb(127, 57, 57);
}

.message-box {
   padding: 10px 0;
}

.message.success {
   color: rgb(53, 114, 53);
   margin-bottom: 10px;
}

.image-box p {
   color: var(--color-white);
}



</style>
