<template>
   <div class="page">
      <ContentBox class="image-box">
         <Header />
         <h2>06/27/2022 - 07/24/2022</h2>
         <h1>Yunjing Deskmats</h1>
         <p>Designed by Jun Huang, our club's design lead, the Yunjing deskmats are our club's inaugural project. We were inspired by Asian architectural scenery and moon gateway iconography.</p>
         <CustomButton content="Shop Now" url="https://keyspensory.store/products/gb-ucikeebclub-yunjing-deskmat" />
      </ContentBox>
      <ContentBox class="info-box">
         <h1>Additional Information</h1>
         <div class="info">
            <div class="left">
               <h3>Specifications</h3>
               <ul>
                  <li>900 x 400 x 4 mm</li>
                  <li>Same color stitched side edges</li>
               </ul>
               <h3>Group Buy Information</h3>
               <p><strong>Price: $20.00</strong></p>
               <p class="gb-info">Start: Monday, June 27th, 2022 at 12:00 PM PDT<br>End: Start: Monday, June 27th, 2022 at 12:00 PM PDT<br><br>Lead Time: 4-6 weeks<br><br>Global Vendor: <a target="__blank" href="https://keyspensory.store/products/gb-ucikeebclub-yunjing-deskmat">Keyspensory</a><br>(For delivery outside of UCI only)</p>
            </div>
            <div class="right">
               <h3>Delivery via UCI</h3>
               <p>If you are affiliated with UCI (students, faculty, etc.) and are available to pick up your order on-campus, please order using the Local Pickup form below.</p>
               <CustomButton content="Local Order" class="disabled"/>
               <p><strong>For instructions on how to pay for your local order, see our payment guide.</strong></p>
               <CustomButton content="Payment Guide" class="disabled" />
            </div>
         </div>
      </ContentBox>
      <div class="photos-title">
         <h1>Renders and Photos</h1>
         <p>Disclaimer: Rendered images may not be perfect representations of the real-world products. Products consist of deskmats only; keyboards not included.</p>
      </div>
      <ContentBox>
         <div class="product-photos">
            <ProductPhoto
               v-for="photo in productphotos"
               :key="photo"
               :product="photo"
            />
         </div>
      </ContentBox>
   </div>
</template>

<script setup>
import ContentBox from "../../../components/ContentBox.vue"
import Header from "../../../components/Header.vue"
import CustomButton from "../../../components/CustomButton.vue"
import ProductPhoto from "../ProductPhoto.vue"

import { ref } from "vue"

/*
 * TODO
 * change this class to be product-agnostic, and take in any data form of a product & show.
 * also have it be dynamically linked to the router so pages are dynamically created & linked.
 * future me problem
 */

const PPhoto = (title, images) => {
   return {
      title: title,
      images: images
   }
}

const productphotos = ref([
   PPhoto(
      "Periwinkle (Blue)", 
      [
         require("../../../assets/groupbuys/yunjing/c_blue_1.webp"),
         require("../../../assets/groupbuys/yunjing/c_blue_2.webp"),
         require("../../../assets/groupbuys/yunjing/c_blue_3.webp"),
         require("../../../assets/groupbuys/yunjing/c_blue_4.webp"),
      ]),
   PPhoto(
      "Peony (Pink)", 
      [
         require("../../../assets/groupbuys/yunjing/c_pink_1.webp"),
         require("../../../assets/groupbuys/yunjing/c_pink_2.webp"),
         require("../../../assets/groupbuys/yunjing/c_pink_3.webp"),
         require("../../../assets/groupbuys/yunjing/c_pink_4.webp"),
      ]),
   PPhoto(
      "Chrysanthemum (Gold)", 
      [
         require("../../../assets/groupbuys/yunjing/c_gold_1.webp"),
         require("../../../assets/groupbuys/yunjing/c_gold_2.webp"),
         require("../../../assets/groupbuys/yunjing/c_gold_3.webp"),
         require("../../../assets/groupbuys/yunjing/c_gold_4.webp"),
      ]),
   PPhoto(
      "Lavender (Purple)", 
      [
         require("../../../assets/groupbuys/yunjing/c_purple_1.webp"),
         require("../../../assets/groupbuys/yunjing/c_purple_2.webp"),
         require("../../../assets/groupbuys/yunjing/c_purple_3.webp"),
         require("../../../assets/groupbuys/yunjing/c_purple_4.webp"),
      ])
])
</script>

<style scoped>
@media (max-width: 750px) {
   .image-box {
      height: fit-content !important;
   }

   .image-box h2 {
      font-size: 20px !important;
      padding-top: 16px !important;
   }

   .image-box .button {
      margin-bottom: 5vw !important;
   }

   .info-box h1 {
      padding-top: 24px !important;
      padding-bottom: 16px !important;
   }

   .info {
      padding-bottom: 5vw !important;
   }

   .right .button:last-child {
      margin-bottom: 0 !important;
   }

   .info-box p, 
   .info-box li,
   .info-box a {
      font-size: 16px !important;
   }

   /* todo make this a component */
   .photos-title {
      padding: 32px 5% 10px 5% !important;
      flex-direction: column !important;
      align-items: flex-start !important;
   }
   .photos-title > * {
      text-align: left !important;
   }

   .product-photos {
      padding-top: 5vw !important;
   }
}

p, a, li, h3 {
   color: var(--color-primary);
}

p, a, li {
   font-size: 20px;
}
.image-box::before {
   background-image: url("../../../assets/groupbuys/yunjing/preview.webp");
}

.image-box h2 {
   color: var(--color-secondary);
   font-weight: 400;
   padding-top: 140px;
}

.image-box h1 {
   padding-top: 12px;
}

.image-box p {
   padding-top: 18px;
   max-width: 730px;
}

.image-box .button {
   padding-bottom: 0;
   margin-bottom: 225px;
   margin-top: 24px;
}

.info-box h1 {
   padding-top: 50px;
   padding-bottom: 33px;
   margin: 0;
}

.info {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 30px;

   padding-bottom: 50px;
}

.info .gb-info {
   white-space: pre-wrap;
}

.gb-info a {
   color: var(--color-accent);
}

.info h3 {
   font-size: 24px;
   text-transform: none;
   padding-bottom: 8px;
}

ul {
   margin: 0;
   padding-bottom: 24px;
}

.info > * {
   flex: 1;
}

.info .button {
   margin-top: 24px;
   margin-bottom: 24px;
}

.photos-title {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;

   padding: 64px 10% 64px 10%;

   align-items: center;
}

.photos-title > * {
   flex: 1;
}

.photos-title p {
   max-width: 450px;
   text-align: right;
   font-size: 16px;
}

.product-photos {
   display: flex;
   flex-direction: column;
   gap: 36px;

   padding: 64px 0 64px 0;
}
</style>
