const firebase = require("./firebase.js")

let data = undefined

const refresh = async () => {
   data = (await firebase.get_doc_path("public/site-data"))
}

const get_data = async () => {
   if (!data) await refresh()
   return data
}

module.exports = {
   // get_upcoming_events: async () => {
   //    let events = (await firebase.get_doc_path("public/site-data")).upcoming_events
   //    return events ? events : []
   // },

   // get_past_events: async () => {
   //    let events = (await firebase.get_doc_path("public/site-data")).past_events
   //    return events ? events : []
   // },

   // get_members: async () => {
   //    let members = (await firebase.get_doc_path("public/site-data")).members
   //    return members ? members : []
   // },

   // get_sponsors: async () => {
   //    let sponsors = (await firebase.get_doc_path("public/site-data")).sponsors
   //    return sponsors ? sponsors : []
   // } 

   refresh,

   get_upcoming_events: async () => {
      let events = (await get_data()).upcoming_events
      return events ? events : []
   },

   get_past_events: async () => {
      let events = (await get_data()).past_events
      return events ? events : []
   },

   get_members: async () => {
      let members = (await get_data()).members
      return members ? members : []
   },

   get_sponsors: async () => {
      let sponsors = (await get_data()).sponsors
      return sponsors ? sponsors : []
   } 
}
