<template>
   <div class="product-box">
      <img class="photo" alt="product preview" :src="product.images[current_image]" />
      <div class="info">
         <h3>{{ product.title }}</h3>
         <div class="photo-small-box">
            <img
               alt="product preview small"
               class="photo-small"
               v-for="image in product.images"
               :key="image"
               :src="image"
               @click="select_image(product.images.indexOf(image))"
            />
         </div>
      </div>
   </div>
</template>

<script setup>

import { defineProps, ref } from "vue"

defineProps([
   "product"
])

const current_image = ref(0)

const select_image = (img) => {
   current_image.value = img
}

</script>

<style scoped>

@media (max-width: 1260px) {
   .product-box {
      flex-direction: column !important;
   }

   .photo {
      border-radius: 24px !important;
   }

   .info {
      padding: 0 !important;
   }
}

@media (max-width: 750px) {
   .info {
      margin-left: 0 !important;
      padding: 10px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .photo-small-box {
      justify-content: center;
   }

   h3 {
      font-size: 24px !important;
   }

   .photo-small {
      width: 115px !important;
      height: 90px !important;
   }
}

.product-box {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.photo {
   max-width: 720px;
   max-height: 400px;
   
   width: 100%;

   object-fit: cover;
   aspect-ratio: 16/9;

   flex: 2;
}

.info {
   flex: 1;

   margin-left: 72px;
   margin-top: 36px;

   color: var(--color-primary);
}

.photo-small {
   width: 142px;
   height: 116px;
   object-fit: cover;
   border-radius: 12px;

   opacity: 0.75;

   cursor: pointer;

   transition: 100ms opacity;
}

.photo-small:hover {
   opacity: 1;
}

.photo-small-box {
   display: flex;
   flex-wrap: wrap;
   align-items: center;

   gap: 14px 16px;
}

h3 {
   font-size: 36px;
   text-transform: none;
   margin-bottom: 24px;
}

</style>
