<template>
   <div class="reason-box">
      <img :src="groupbuy.image" />
      <div class="reason">
         <p class="date">{{ groupbuy.date }}</p>
         <h2>{{ groupbuy.title }}</h2>
         <p>{{ groupbuy.description }}</p>
         <CustomButton content="Learn more" :url="groupbuy.url" />
      </div>
   </div>
</template>

<script>
import CustomButton from "../../components/CustomButton.vue";

export default {
   name: "Groupbuy",
   components: {
      CustomButton
   },
   props: {
      groupbuy: Object
   }
}

</script>

<style scoped>

@media (max-width: 750px) {
   /* mobile */
   .reason-box {
      height: auto !important;
   }
   img {
      position: static !important;
      width: 100% !important;
      margin-top: 10%;
   }

   .reason {
      margin: 0 !important;
      width: 100% !important;
   }

   .reason-box {
      padding-bottom: 5vw !important;
      height: fit-content !important;
   }

   .reason-box img {
      margin-top: 0 !important;
   }

   .button {
      margin-bottom: 0 !important;
   }

   .date {
      padding-top: 16px !important;
      font-size: 18px !important;
   }
}

.reason-box {
   position: relative;

   height: 410px;

   padding-bottom: 64px;
}

.reason {
   margin-left: 55%;
}

.reason * {
   margin-bottom: 20px;
}

.reason-box.left .reason {
   margin-left: 5%;
}

p, h2 {
   color: var(--color-primary);
}

h2 {
   text-transform: none;
   font-size: 36px;
   font-weight: bold;
}

p {
   font-size: 20px;
   white-space: pre-wrap;
}

.date {
   color: var(--color-secondary);
   margin-bottom: 12px;
   padding-top: 36px;   
}

img {
   width: 750px;
   height: 410px;
   object-fit: cover;

   position: absolute;
   top: 0%;
   right: 50%;
}

.reason-box.left img {
   left: 50%;
}

</style>
