<template>
  <ContentBox class="sponsor-bx">
      <h1>Our Sponsors</h1>
      <p>{{ description }}</p>
      <CustomButton content="Join Discord" url="https://discord.gg/xR5T7fyXTv" />
      <div class="sponsors">
         <Sponsor
            v-for="sponsor in sponsors"
            :key="sponsor"
            :sponsor="sponsor"
         />
      </div>
   </ContentBox>
</template>

<script setup>
import Sponsor from "./Sponsor"
import ContentBox from "../ContentBox.vue"
import CustomButton from "../CustomButton.vue"
import { defineProps, onBeforeMount, ref, } from "vue"

import { get_sponsors } from "../../data/data"

const sponsors = ref([])

onBeforeMount(async () => {
   sponsors.value = await get_sponsors()
})

defineProps([
   "description"
])
</script>

<style scoped>
@media (max-width: 750px) {
   .sponsors {
   }

}

.sponsors {
   display: flex;
   flex-basis: 50%;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   gap: 18px;

   padding-bottom: 64px;

   /* max-width: 1000px; these lines scale sponsors better when # is more than 4
   margin: auto; */
}

/* Our sponsors box */
.sponsor-bx.dark {
   background-color: var(--color-shaded);
}
.sponsor-bx .content p,
.sponsor-bx .content h1 {
   text-align: center;
   color: var(--color-primary);
}

/* special darkmode */
.sponsor-bx.dark .content p,
.sponsor-bx.dark .content h1 {
   color: var(--color-white);
}

.sponsor-bx .content p {
   margin: auto;
   font-size: 20px;
   max-width: 750px;
   padding-top: 16px;
}
.sponsor-bx .content h1 {
   padding-top: 50px;
   margin: 0;
}
.sponsor-bx .content .button {
   margin: auto;
   margin-top: 24px;
   margin-bottom: 48px;
}
</style>
