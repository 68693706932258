const GroupBuy = (image, date, title, description, url) => {
   return {
      image: image,
      date: date,
      title: title,
      description: description,
      url: url
   }
}

const groupbuys = [
   GroupBuy(
      require("../assets/groupbuys/yunjing/preview.webp"),
      "06/27/2022 - 07/24/2022",
      "Yunjing Deskmats",
      "Welcome to Yunjing, Keebs Club at UCI's first desk mat project. Inspired by Asian architectural scenery and moon gateway iconography, we came up with the perfect blend of modern and traditional design.\n\nSize: 900 x 400 x 4 mm\nPrice: $20.00",
      "/group-buys/yunjing"
   ),
]

export default groupbuys
