import { createRouter, createWebHistory } from "vue-router"

import Home from "../pages/Home/Home.vue"
import About from "../pages/About/About.vue"
import Join from "../pages/Join/Join.vue"
import Groupbuys from "../pages/Groupbuys/Groupbuys.vue"
import Events from "../pages/Events/Events.vue"
import PageNotFound from "../pages/PageNotFound/PageNotFound.vue"
import Privacy from "../pages/Privacy/Privacy.vue"
import TOS from "../pages/TOS/TOS.vue"

import Yunjing from "../pages/Groupbuys/Yunjing/Yunjing.vue"
import RSVP from "../pages/RSVP/RSVP-24-spring-keeb-meet.vue"

const routes = [
   { path: "/", name: "Home", component: Home },
   { path: "/about", name: "About", component: About },
   { path: "/join", name: "Join", component: Join },
   { path: "/group-buys", name: "Groupbuys", component: Groupbuys },
   { path: "/events", name: "Events", component: Events },
   { path: "/privacy-policy", name: "Privacy Policy", component: Privacy },
   { path: "/terms-of-service", name: "Terms of Service", component: TOS },
   { path: "/group-buys/yunjing", name: "Yunjing", component: Yunjing },
   { path: "/rsvp/meet", name: "RSVP", component: RSVP },
   { path: "/:pathMatch(.*)*", name: "404", component: PageNotFound }
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
   linkActiveClass: "selected"
})

export default router
