<template>
   <div class="header" ref="header">
      <div @click="open_mobile_menu" class="menu-button">
         <img alt="menu" src="../assets/icons/menu.webp" />
      </div>
      <div class="mobile-menu">
         <div class="menu-box">
            <a @click="close_mobile_menu">Back</a>
            <hr />
            <router-link @click="close_mobile_menu" to="/">Home</router-link>
            <router-link @click="close_mobile_menu" to="/about">About</router-link>
            <router-link @click="close_mobile_menu" to="/join">Join</router-link>
            <router-link @click="close_mobile_menu" to="/events">Events</router-link>
            <router-link @click="close_mobile_menu" to="/group-buys">Group Buys</router-link>
            <!--router-link @click="close_mobile_menu" to="/contact">Contact</router-link-->
            <a @click="close_mobile_menu" href="mailto:keebsclub@uci.edu" target="__blank">Contact</a>
            <a @click="close_mobile_menu" href="https://claq.keebsclubuci.org" target="__blank">Claq</a>
         </div>
         <div @click="close_mobile_menu" class="menu-background"></div>
      </div>
      <div class="header-items left">
         <router-link to="/about">About</router-link>
         <router-link to="/join">Join</router-link>
         <router-link to="/events">Events</router-link>
      </div>
      <router-link to="/" class="header-icon">
         <img src="../assets/icons/smolbean.webp" href="anteater logo" />
      </router-link>
      <div class="header-items right">
         <router-link to="/group-buys">Group Buys</router-link>
         <a target="__blank" href="mailto:keebsclub@uci.edu">Contact</a>
         <a target="__blank" href="https://claq.keebsclubuci.org">Claq</a>
         <!--router-link to="/contact">Contact</router-link-->
      </div>
   </div>
</template>

<script setup>

import { ref } from "vue"

const header = ref()

const open_mobile_menu = () => {
   header.value.classList.add("mobile")
}

const close_mobile_menu = () => {
   header.value.classList.remove("mobile")
}

</script>

<style scoped>

@media (max-width: 750px)
{
   .left,
   .right {
      display: none !important;
   }

   .menu-button {
      display: block !important;
   }

   .header-icon img {
      width: 64px !important;
   }
}

.header {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 90px;
   padding-top: 10px;
   max-width: var(--page-width);

   position: relative;
}

.menu-button {
   position: absolute;
   left: 0%;

   cursor: pointer;

   transition: 100ms opacity;

   display: none;

}

.menu-button img {
   border-radius: 0;
   width: 36px;
}

.menu-button:hover {
   opacity: 0.75;
}

.mobile-menu {
   z-index: 2;
}

.mobile-menu > * {
   top: 0;
   left: 0;
   position: fixed;
}

.header.mobile .menu-background {
   opacity: 1;
   pointer-events: visible;
}
.header.mobile .menu-box {
   transform: translateX(0%);
}

.menu-background {
   width: 100%;
   height: 100vh;

   background-color: var(--color-image-overlay);

   transition: opacity 500ms cubic-bezier(0, 0, 0, 1);

   pointer-events: none;
   opacity: 0;
}

.menu-box {
   z-index: 3;
   width: 235px;
   height: 100vh;

   background-color: var(--color-background);

   border-radius: 0 32px 32px 0;

   display: flex;
   flex-direction: column;
   gap: 10px;

   transition: transform 500ms cubic-bezier(0, 0, 0, 1);

   transform: translateX(-100%);
}

.menu-box *:first-child {
   margin-top: 30px;
}

.menu-box > * {
   margin-left: 30px;
}

.menu-box > hr {
   width: 35%;
}

.header-items {
   flex: 1;

   display: flex;
   gap: 10%;

   text-align: center;
   font-size: 16px;
   user-select: none;
   cursor: pointer;
   color: var(--color-secondary);
}

.header-items *:hover,
.menu-box > *:hover,
.header-items .selected,
.menu-box .selected {
   color: var(--color-accent);
}

.header-items a,
.menu-box a {
   color: var(--color-secondary);
   text-decoration: none;
   cursor: pointer;
}

.header-items.left {
   justify-content: left;
}

.header-items.right {
   justify-content: right;
}

.header-icon img {
   width: 88px;

   transition: opacity 100ms;
}

.header-icon img:hover {
   opacity: 0.5;
}

@media (max-width: 750px) {
   /* todo mobile */
}
</style>
