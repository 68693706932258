<template>
   <div class="event">
      <p class="type">{{ event.type }} event</p>
      <h2 class="title">{{ event.name }}</h2>
      <p class="description">{{ event.description }}</p>
      <div class="info">
         <div class="when">
            <h3>when</h3>
            <p>{{ event.date }}</p>
         </div>
         <div class="where">
            <h3>where</h3>
            <p>{{ event.location }}</p>
            <a :class="event.map_url == '' ? 'disabled' : ''" target="__blank" :href="event.map_url">View Map</a>
         </div>
      </div>
      <div class="buttons">
         <CustomButton v-if="event.button_text != ''" :class="event.disabled ? 'disabled' : ''" :content="event.button_text" :url="event.rsvp_url" />
         <CustomButton v-if="event.long_descripton != ''" content="More Info" @click="open()" />
         <!--Button class="disabled" content="Add to GCal" /-->
      </div>
   </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
import CustomButton from "../../components/CustomButton.vue"

const props = defineProps([
   "event",
   "tag"
])

const emit = defineEmits([
   "open"
])

const open = () => {
   emit("open", props.tag)
}

</script>

<style scoped>

@media(max-width: 750px)
{
   .event {
      padding: 20px !important;
   }
}

.event {
   max-width: 410px;
   height: fit-content;
   background-color: var(--color-body);
   border-radius: 32px;
   color: var(--color-primary);

   padding: 24px;
}

h2 {
   text-transform: none;
}

.type {
   font-size: 16px;
   text-transform: uppercase;
   margin: 0;
   margin-bottom: 12px;
}

.title {
   margin: 0;
   margin-bottom: 12px;

   font-size: 32px;
   font-family: "Franklin Gothic Medium", sans-serif;
}

.description {
   margin: 0;
   margin-bottom: 28px;
   font-size: 20px;
}

.info {
   display: flex;
   flex-direction: row;
   margin-bottom: 36px;
}

.info > * {
   flex: 1;
}

.info h3 {
   text-transform: uppercase;
   font-size: 20px;
}

a:not(.button).disabled {
   display: none;
   cursor: not-allowed;
   color: var(--color-secondary) !important;
}

.info p,
.info a {
   max-width: 170px;
   font-size: 18px;
}

.info a {
   color: var(--color-accent);
}
.info a:hover {
   color: var(--color-accent2);
}

button {
   margin: auto;
}

.when {
   flex: 1;
}

.where {
   flex: 0.5;
}

.buttons {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;

   gap: 10px;
}
</style>
